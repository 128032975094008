<template>
    <v-container class="fill-height">
        <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="6" lg="4">
                <v-dialog :value="true" max-width="575" persistent>
                    <v-card class="fill-height">
                        <v-toolbar color="primary" dark dense>
                            <v-toolbar-title>Réinitialisation de mot de passe</v-toolbar-title>
                        </v-toolbar>

                        <v-card-text class="pb-0 pt-4">
                            <ValidationObserver ref="observer">
                                <ValidationProvider ref="passwordInput" v-slot="{ errors }" name="Client" rules="required" vid="confirmInput">
                                    <v-text-field v-model="password" label="Mot de passe" :type="showPassword ? 'text' : 'password'" @click:append="showPassword = !showPassword" :error-messages="errors" outlined dense>
                                        <template v-slot:append>
                                            <v-icon tabindex="-1" @click="showPassword = !showPassword"> {{ showPassword ? 'fas fa-eye' : 'fas fa-eye-slash' }} </v-icon>
                                        </template>
                                    </v-text-field>
                                </ValidationProvider>

                                <ValidationProvider v-slot="{ errors }" name="Client" rules="required|confirmed:confirmInput">
                                    <v-text-field v-model="passwordConfirmation" label="Confirmation" :type="showPasswordConfirmation ? 'text' : 'password'" :error-messages="errors" @keyup.enter="submit()" outlined dense>
                                        <template v-slot:append>
                                            <v-icon tabindex="-1" @click="showPasswordConfirmation = !showPasswordConfirmation"> {{ showPasswordConfirmation ? 'fas fa-eye' : 'fas fa-eye-slash' }} </v-icon>
                                        </template>
                                    </v-text-field>
                                </ValidationProvider>
                            </ValidationObserver>
                        </v-card-text>

                        <v-card-actions class="pt-0">
                            <v-btn @click="submit()" class="ml-2" color="primary" small depressed> Confirmer </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'Alerts',

    data: () => ({
        password: '',
        passwordConfirmation: '',

        showPassword: false,
        showPasswordConfirmation: false
    }),

    methods: {
        async submit() {
            try {
                const valid = await this.$refs.observer.validate();
                if (!valid) {
                    return;
                }
                this.setLoading(true);

                const body = { password: this.password, token: this.$route.query.token };
                const { success, err } = await this.repos.auth.resetPassword(body);

                if (success) {
                    this.$notify({
                        title: 'Information',
                        text: 'Votre mot de passe à bien été réinitialisé',
                        type: 'success'
                    });
                    this.$router.push('/connexion');
                } else {
                    throw new Error(err);
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: err.message,
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        }
    }
};
</script>
